import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import CommonCard from "../card";
import config from "../../config";
import line from "../../assets/1.png"
import shapemyhouse from "../../assets/images/shapemyhouse.png"

const projects = [
  {
    id: 1,
    title: "Shape My House",
    location: "Delhi NCR",
    imgSrc: shapemyhouse,
    link: "http://www.shapemyhouse.com",
  },
];

function Project3_Trading() {
  useEffect(() => {
    AOS.init({
      disable: "phone",
      duration: 1000,
      easing: "ease-out-cubic",
    });
  }, []);

  return (
    <div data-aos-anchor-placement="top-center" data-aos="fade-up">
      <div
            className="text-2xl text-center cel2 mb-6 bg-gradient-to-r from-[#8B6A1F] via-[#D98E10] to-[#8B6A1F] bg-clip-text text-transparent
"
          >
        Construction & Collaboration
        <img src={line} alt="" className=" mx-auto h-4 mt-3" />
      </div>

      {/* (Project Component-2) */}
      <div className="flex flex-col">
        <section className="grid gap-5 m-12 -mt-2 md:m-auto p-3 md:gap-20">
          {projects.map((project) => (
            <div className="w-full" key={project.id}>
              <CommonCard
                title={project.title}
                location={project.location}
                imgSrc={project.imgSrc}
                link={project.link}
              />
            </div>
          ))}
        </section>
      </div>
    </div>
  );
}

export default Project3_Trading;
